import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Gelek`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Turning every geek dream into reality.`}</p>
    </blockquote>
    <p>{`Ever realized how difficult it is to kick off an electronic DIY project?
Microcontroller hunting, solution making, prototyping, materials preparing --
it's all such a hassle! We (who are also geeks) decided to fix it, once and for
all. That's how Gelek was founded.`}</p>
    <p>{`We provide tools and hardwares to walk energetic creators through the entire
invention process: including microcontroller boards, starter kits,
self-training materials, various components and code libraries.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      