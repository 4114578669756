import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Gelek`}</h1>
    <p>{`Microcontroller boards and other good stuffs for geeks!`}</p>
    <small><a href="http://jp.gelek.tech">🇯🇵 日本語版サイトへ Goto Japanese site</a></small>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      