import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DownloadCard = makeShortcode("DownloadCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Downloads`}</h2>
    <DownloadCard title="Gelek R3 Resources" link="https://drive.google.com/file/d/1GSg8CwihEGKzCjf4itmqXXPLIm1ECfuI/view?usp=sharing" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="DownloadCard">
  Datasheets, tutorials and free eBooks, everything you need to get started on
  the Gelek R3 Microcontroller Board.
    </DownloadCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      